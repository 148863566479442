<template>
  <div class="reg_order">
    <div class="layer_02">
      <div class="layer_top">
        <h2 class="layer_top_title">페스티벌 결제내역/진행현황</h2>
        <span class="btn_close" @click="$emit('close')">
          <img
            src="@/assets/img/close.png"
            style="width: 15px; height: 15px"
            alt="닫기"
          />
        </span>
      </div>
      <div class="layer_02_box">
        <form action="#">
          <table class="layer_01_table">
            <colgroup>
              <col style="width: 158px" />
              <col style="width: 760px" />
            </colgroup>
            <tr class="layer_tbl" v-if="id">
              <td class="layer_tbl_title pb_15">결제일</td>
              <td class="pb_15">
                {{ registerDate }}
              </td>
            </tr>
            <tr class="layer_tbl">
              <td class="layer_tbl_title pb_15">상품명</td>
              <td class="pb_15" v-if="id">
                {{ productName }}
              </td>
              <td class="pb_15" v-else>
                <base-select
                  :options="productNames"
                  v-model="selectedProductName"
                  placeholder="선택"
                />
              </td>
            </tr>
            <template v-if="id">
              <tr class="layer_tbl">
                <td class="layer_tbl_title pb_15">운영개수</td>
                <td class="pb_15">
                  {{ operatingCount }}
                </td>
              </tr>
              <tr class="layer_tbl">
                <td class="layer_tbl_title pb_15">운영일수</td>
                <td class="pb_15">
                  {{ operatingDay }}
                </td>
              </tr>
              <!-- <tr class="layer_tbl">
                <td class="layer_tbl_title pb_15">할인금액</td>
                <td class="pb_15">
                  {{ discountRate }}
                </td>
              </tr> -->
            </template>
            <tr class="layer_tbl">
              <td class="layer_tbl_title pb_15">구매자</td>
              <td class="pb_15" v-if="id">{{ userName }}</td>
              <td class="pb_15" v-else>
                <input
                  type="text"
                  class="text_box_05"
                  readonly
                  v-model="userName"
                />
                <base-button type="upload" class="ml_30" @click="callUser"
                  >불러오기</base-button
                >
              </td>
            </tr>
            <tr class="layer_tbl">
              <td class="layer_tbl_title pb_15">축제명</td>
              <td class="pb_15">
                <input
                  type="text"
                  class="text_box_02"
                  v-model="referenceFestivalName"
                />
              </td>
            </tr>
            <tr class="layer_tbl">
              <td class="layer_tbl_title pb_15">축제 기간</td>
              <td class="pb_15">
                <date-picker
                  v-model="referenceFestivalStartDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      class="dt_base_input"
                      readonly
                    />
                  </template>
                </date-picker>
                <span class="ml_04 mr_04">~</span>
                <date-picker
                  v-model="referenceFestivalEndDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      class="dt_base_input"
                      readonly
                    />
                  </template>
                </date-picker>
              </td>
            </tr>
            <tr class="layer_tbl" v-if="id">
              <td class="layer_tbl_title pb_15">진행상태</td>
              <td class="pb_15">
                <base-select
                  :options="states"
                  v-model="selectedState"
                  placeholder="선택"
                />
              </td>
            </tr>
            <tr class="layer_tbl" v-if="id">
              <td class="layer_tbl_title pb_15">금액</td>
              <td class="pb_15" v-if="total">
                {{ total | comma }}
              </td>
            </tr>
            <tr class="layer_tbl">
              <td class="layer_tbl_title pb_15">결제방법</td>
              <td class="pb_15" v-if="id">
                {{ selectedPgType == "transfer" ? "무통장입금" : "카드결제" }}
              </td>
              <td class="pb_15" v-else>
                <base-select
                  :options="pgTypes"
                  v-model="selectedPgType"
                  placeholder="선택"
                />
              </td>
            </tr>
            <tr
              class="layer_tbl"
              v-if="
                id &&
                refundDate != '0000-00-00 00:00:00' &&
                pgType == '카드결제'
              "
            >
              <td class="layer_tbl_title pb_15">카드취소일자</td>
              <td class="pb_15" colspan="3">
                {{ refundDate }}
              </td>
            </tr>
            <tr
              class="layer_tbl"
              v-if="
                id &&
                refundDate != '0000-00-00 00:00:00' &&
                pgType == '무통장입금'
              "
            >
              <td class="layer_tbl_title pb_15">계좌환불일자</td>
              <td class="pb_15">
                <date-picker
                  v-model="refundDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      type="text"
                      :value="inputValue"
                      v-on="inputEvents"
                      class="dt_base_input"
                      readonly
                    />
                  </template>
                </date-picker>
              </td>
            </tr>
          </table>
        </form>
      </div>
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="edit">{{
          id ? "수정" : "등록"
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
import BaseSelect from "./core/BaseSelect.vue";
import UserCall from "@/components/UserCall.vue";

export default {
  components: {
    BaseButton,
    BaseSelect,
  },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      registerDate: "",
      productDeleted: false,
      productNames: [],
      selectedProductName: "",
      productName: "",
      operatingCount: "",
      operatingDay: "",
      amount: 0,
      discountRate: "",
      total: 0,

      userName: "",
      referenceFestivalName: "",
      referenceFestivalStartDate: this.$date().format("YYYY-MM-DD"),
      referenceFestivalEndDate: this.$date().format("YYYY-MM-DD"),
      states: [
        { name: "주문(결제완료)", value: 1 },
        { name: "검수 중", value: 2 },
        { name: "보완요청", value: 3 },
        { name: "개발진행", value: 4 },
        { name: "개발완료", value: 5 },
        { name: "운영 중", value: 6 },
        { name: "운영 종료", value: 7 },
        { name: "취소(환불)", value: 8 },
        { name: "환불", value: 9 },
        { name: "주문(결제전)", value: 10 },
      ],
      selectedState: "",
      pgTypes: [
        { name: "카드결제", value: "card" },
        { name: "무통장입금", value: "transfer" },
      ],
      selectedPgType: "",
      pgType: "",
      istax: 0,
      taxTotal: 0,
      taxsaveFlag: 0,
      taxsaveTrade: "",
      taxsaveIdnum: "",
      cardRefundDate: "",
      billingData: {},
      refundDate: this.$date().format("YYYY-MM-DD"),
      userId: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.productFindAll().then(
        (response) => {
          const { data } = response;
          data.items.forEach((item) => {
            this.productNames.push({ name: item.name, value: item.id });
          });
        },
        (error) => {
          console.log(error);
        }
      );
      await client.orderFindone(this.id).then(
        (response) => {
          const { data } = response;

          this.registerDate =
            this.id && data.registerDate
              ? this.$d(new Date(data.registerDate), "short")
              : "";
          this.selectedProductName =
            this.id && data.product ? data.product.id : "";
          this.productName = this.id && data.product ? data.product.name : "";
          this.operatingCount =
            this.id && data.product ? data.optionData.operatingCount : "";
          this.operatingDay =
            this.id && data.product ? data.optionData.operatingDay : "";
          this.amount = this.id && data.product ? data.product.amount : "";
          this.discountRate =
            this.id && data.product ? data.optionData.discountRate : "";
          this.referenceFestivalName =
            this.id && data.reference ? data.reference.festivalName : "";
          this.referenceFestivalStartDate =
            this.id && data.reference
              ? this.$date(data.reference.festivalStartDate).format(
                  "YYYY-MM-DD"
                )
              : this.$date().format("YYYY-MM-DD");
          this.referenceFestivalEndDate =
            this.id && data.reference
              ? this.$date(data.reference.festivalEndDate).format("YYYY-MM-DD")
              : this.$date().format("YYYY-MM-DD");
          this.selectedState = this.id && data.status ? data.status : "";
          this.billingData =
            this.id && data.billingData ? data.billingData : "";
          this.istax = this.id ? data.istax : 0;
          this.taxTotal = this.id ? data.taxTotal : 0;
          this.taxsaveFlag = this.id ? data.taxsaveFlag : 0;
          this.taxsaveTrade = this.id ? data.taxsaveTrade : "";
          this.taxsaveIdnum = this.id ? data.taxsaveIdnum : "";
          this.total = this.id && data.total ? data.total : "-";
          this.selectedPgType = this.id && data.pgType ? data.pgType : "";
          this.refundDate = this.id && data.refundDate ? data.refundDate : "";
          this.userId = this.id && data.user.id ? data.user.id : "";
          this.userName = data.user ? data.user.name : "";
          // 상품정보 삭제됐을 경우 예외처리 인데 지금 안씀
          /* if (this.id && data.product) {
            const find = this.productNames.find(
              (item) => item.value == data.product.id
            );
            if (find) {
              this.productDeleted = true;
            }
          }
          console.log(this.productDeleted);
          */
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async edit() {
      let params = {
        userId: this.userId,
        status: this.selectedState,
        productId: this.selectedProductName,
        total: this.total,
        taxTotal: this.taxTotal,
        istax: this.istax,
        taxsaveFlag: this.taxsaveFlag,
        taxsaveTrade: this.taxsaveTrade,
        taxsaveIdnum: this.taxsaveIdnum,
        pg: "payple",
        pgType: this.selectedPgType,
        referenceFestivalName: this.referenceFestivalName,
        referenceFestivalStartDate: this.referenceFestivalStartDate,
        referenceFestivalEndDate: this.referenceFestivalEndDate,
        billingData: this.billingData,
        optionData: {
          product_discount_rate: this.discountRate,
          product_operating_count: this.operatingCount,
          product_operating_day: this.operatingDay,
        },
      };
      if (!this.selectedProductName) {
        alert("상품명을 선택해주세요");
      } else if (!this.userName) {
        alert("구매자를 선택해주세요");
      } else if (!this.referenceFestivalName) {
        alert("축제명을 입력해주세요");
      } else if (!this.id && !this.selectedPgType) {
        alert("결제방법을 선택해주세요");
      } else {
        if (this.id) {
          await client.orderModify(this.id, params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "결제내역/진행현황이 정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          await client.orderAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "결제내역/진행현황이 정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    async fetchUserId(userId) {
      this.userId = userId.userId;
      await client.userFindone(this.userId).then((response) => {
        const { data } = response;
        this.userName = data.name ? data.name : "";
      });
    },
    callUser() {
      this.$modal.show(
        UserCall,
        {},
        {
          width: 804,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              this.fetchUserId(response.params);
            }
          },
        }
      );
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
