<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">결제내역/진행현황</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 188px" />
            <col style="width: 128px" />
            <col style="width: 158px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">결제일</td>
            <td class="box_style_02">
              {{ registerDate }}
            </td>
            <td class="box_style_02 list_title_style">상품명</td>
            <td class="box_style_02">
              {{ product.name }}
            </td>
            <td class="box_style_02 list_title_style">구매자</td>
            <td class="box_style_02">
              {{ userName }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">운영일수</td>
            <td class="box_style_02">
              {{ optionData.operatingDay }}
            </td>
            <td class="box_style_02 list_title_style">운영개수</td>
            <td class="box_style_02">
              {{ optionData.operatingCount }}
            </td>
            <td class="box_style_02 list_title_style">할인금액</td>
            <td class="box_style_02">
              <!-- {{ optionData.discountRate }} -->
              {{ optionData.discountPrice }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">축제명</td>
            <td class="box_style_02">
              {{ reference.festivalName }}
            </td>
            <td class="box_style_02 list_title_style">축제 기간</td>
            <td class="box_style_02" colspan="3">
              {{ reference.festivalStartDate }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">진행상태</td>
            <td class="box_style_02">
              {{ status }}
            </td>
            <td class="box_style_02 list_title_style">금액</td>
            <td class="box_style_02">
              {{ total | comma }}
            </td>
            <td class="box_style_02 list_title_style">결제방법</td>
            <td class="box_style_02">
              {{ pgType }}
            </td>
          </tr>
          <tr class="line_style">
            <td
              class="list_title_style box_style_02"
              v-if="
                id &&
                refundDate != '0000-00-00 00:00:00' &&
                pgType == '카드결제'
              "
            >
              카드취소일자
            </td>
            <td
              class="box_style_02"
              colspan="2"
              v-if="
                id &&
                refundDate != '0000-00-00 00:00:00' &&
                pgType == '카드결제'
              "
            >
              {{ refundDate }}
            </td>

            <td
              class="list_title_style box_style_02"
              v-if="
                id &&
                refundDate != '0000-00-00 00:00:00' &&
                pgType == '무통장입금'
              "
            >
              계좌환불일자
            </td>
            <td
              class="box_style_02"
              colspan="2"
              v-if="
                id &&
                refundDate != '0000-00-00 00:00:00' &&
                pgType == '무통장입금'
              "
            >
              {{ refundDate }}
            </td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      registerDate: "",
      userName: "",
      product: {
        name: "",
      },
      optionData: {
        operatingDay: "",
        operatingCount: "",
        discountRate: "",
      },
      reference: {
        festivalName: "",
        festivalStartDate: "",
      },
      status: "",
      total: 0,
      pgType: "",
      refundDate: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.orderFindone(this.id).then(
        (response) => {
          const { data } = response;
          // console.log(data);
          this.registerDate = this.$d(new Date(data.registerDate), "short");
          this.userName = data.user.name ? data.user.name : "-";
          this.product.name = data.product ? data.product.name : "-";
          this.optionData.operatingDay = data.product
            ? data.optionData.operatingDay
            : "-";
          this.optionData.operatingCount = data.product
            ? data.optionData.operatingCount
            : "-";
          this.optionData.discountRate = data.product
            ? data.optionData.discountRate
            : "-";
          this.total = data.total ? data.total : 0;
          this.reference.festivalName = data.reference
            ? data.reference.festivalName
            : "-";
          try {
            this.reference.festivalStartDate = `${this.$d(
              new Date(data.reference.festivalStartDate).getTime(),
              "short"
            )} ~ ${this.$d(
              new Date(data.reference.festivalEndDate).getTime(),
              "short"
            )}`;
          } catch (e) {
            if (e) {
              this.reference.festivalStartDate = "-";
            }
          }
          if (data.status == 1) {
            this.status = "주문(결제완료)";
          } else if (data.status == 2) {
            this.status = "검수 중";
          } else if (data.status == 3) {
            this.status = "보완요청";
          } else if (data.status == 4) {
            this.status = "개발진행";
          } else if (data.status == 5) {
            this.status = "개발완료";
          } else if (data.status == 6) {
            this.status = "운영 중";
          } else if (data.status == 7) {
            this.status = "운영 종료";
          } else if (data.status == 8) {
            this.status = "취소(환불)";
          }
          this.pgType = data.pgType;
          this.refundDate = data.refundDate;
          console.log(data);

          if (data.pgType == "card") {
            this.pgType = "카드결제";
          } else if (data.pgType == "transfer") {
            this.pgType = "무통장입금";
          } else {
            this.pgType = "기타";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  filters: {
    comma(val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
