<template>
  <div>
    <section class="cont_01">
      <form @submit="onSearch">
        <div class="searchform_cont_02">
          <!-- <span>
            <label>결제방법</label>
            <base-select
              :options="payments"
              v-model="selectedPayment"
              placeholder="선택"
            />
          </span> -->
          <span>
            <label>결제일</label>
            <span>
              <date-picker
                v-model="search.startDate.value"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base"
                    readonly
                  />
                </template>
              </date-picker>
              <span class="ml_04 mr_04">~</span>
              <date-picker
                v-model="search.endDate.value"
                :popover="{ visibility: 'click' }"
                :min-date="search.startDate.value"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base"
                    readonly
                  />
                </template>
              </date-picker>
              <!-- <span>
                <button
                  type="button"
                  class="date_search_btn"
                  @click="setDate(0)"
                >
                  오늘
                </button>
                <button
                  type="button"
                  class="date_search_btn"
                  @click="setDate(1)"
                >
                  어제
                </button>
                <button
                  type="button"
                  class="date_search_btn"
                  @click="setDate(2)"
                >
                  이번주
                </button>
                <button type="button" class="date_search_btn">
                  사용자 지정
                </button>
              </span> -->
            </span>
          </span>
          <span>
            <label>축제 기간</label>
            <date-picker
              v-model="search.referenceFestivalStartDate.value"
              :popover="{ visibility: 'click' }"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
            <span class="ml_04 mr_04">~</span>
            <date-picker
              v-model="search.referenceFestivalEndDate.value"
              :popover="{ visibility: 'click' }"
              :min-date="search.referenceFestivalStartDate.value"
            >
              <template v-slot="{ inputValue, inputEvents }">
                <input
                  type="text"
                  :value="inputValue"
                  v-on="inputEvents"
                  class="dt_base"
                  readonly
                />
              </template>
            </date-picker>
          </span>
          <span>
            <label>진행상태</label>
            <base-select :options="states" v-model="selectedState" />
          </span>
          <span>
            <label>검색어</label>
            <base-select :options="searchTypeOptions" v-model="search.type" />
            <input
              type="text"
              v-model="search.q"
              placeholder="검색어를 입력해 주세요."
            />
            <base-button type="primary" nativeType="submit">검색</base-button>
          </span>
        </div>
      </form>
    </section>
    <section class="cont_02">
      <div class="total_box addnumbox">
        <div>
          <div class="srch_total pl_40">
            <span class="srch_total_title">검색 :</span>
            <span class="total_count">{{ $n(searchTotalItems) }}</span>
            <span>명</span>
          </div>
          <div class="total ml_31">
            <span class="total_title">전체 :</span>
            <span class="total_count">{{ $n(totalItems) }}</span>
            <span>건</span>
          </div>
        </div>
        <span class="pr_40">
          <base-select
            :options="search.size.option"
            v-model="search.size.value"
            @input="fetch(1)"
            placeholder="선택"
          />
        </span>
      </div>
      <div class="table_area">
        <div>
          <base-table
            :useNo="true"
            :totalItems="totalItems"
            :currentPage="currentPage"
            :pageSize="pageSize"
            :loading="contentLoading"
            :fields="fields"
            :items="items"
            @edit="onEditClick"
            @delete="onDeleteClick"
            @click="onTableClick"
            @sort="onSortClick"
            :clickedNum="clickedNum"
          />
        </div>
        <base-pagination
          :total="totalItems"
          :loading="contentLoading"
          :currentPage="currentPage"
          :pageCount="totalPages"
          :perPage="pageSize"
          @change="fetch"
        />
      </div>
      <div class="btn_right_wrapper">
        <base-button
          class="mr_30"
          type="download"
          @click.prevent.stop="
            download(
              'advertisement?download=xls',
              '스탬프 팝 페스티벌 결제내역/진행현황',
              'xls'
            )
          "
          :disabled="btnDisabled"
          >{{ downloadText }}</base-button
        >
        <base-button type="primary" @click="register">신규등록</base-button>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import BaseTable from "@/components/core/BaseTable/BaseTable.vue";
import BasePagination from "@/components/core/BasePagination.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import Dialog from "@/components/Dialog.vue";
import RegOrder from "@/components/RegOrder.vue";
import DetailOrder from "@/components/DetailOrder.vue";
import File from "@/common/file";
import { API_URL, SERVICE_API_URL } from "@/common/config";

export default {
  name: "OrderFestivalPayment",
  components: {
    BaseSelect,
    BaseButton,
    BaseTable,
    BasePagination,
  },
  data() {
    return {
      downloadText: "엑셀 다운로드",
      btnDisabled: false,
      currentSort: "",
      clickedNum: 0,
      fields: [
        {
          key: "registerDate",
          width: "150px",
          label: "결제일",
          formatter: {
            name: "date",
            type: "short",
          },
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "user.name",
          width: "140px",
          label: "회원명",
          useClick: true,
        },
        {
          key: "user.email",
          width: "200px",
          label: "이메일",
          useClick: true,
        },
        {
          key: "product.name",
          width: "120px",
          label: "상품명",
          useClick: true,
        },
        {
          key: "reference.festivalName",
          width: "200px",
          label: "축제명",
          useSort: true,
          clickedNum: 0,
        },
        {
          key: "reference.festivalStartDate",
          width: "200px",
          label: "축제기간",
        },
        {
          key: "user.name",
          width: "140px",
          label: "구매자",
        },
        {
          key: "status",
          width: "140px",
          label: "진행상태",
        },
        {
          key: "optionData.operatingCount",
          width: "100px",
          label: "운영개수",
          clickedNum: 0,
          // formatter: {
          //   name: "number",
          // },
        },
        {
          key: "optionData.operatingDay",
          width: "100px",
          label: "운영일수",
          clickedNum: 0,
          formatter: {
            name: "number",
          },
        },
        {
          key: "total",
          width: "100px",
          label: "금액",
          formatter: {
            name: "number",
          },
        },
        {
          key: "pgType",
          width: "100px",
          label: "결제방법",
        },
      ],
      items: [],
      totalItems: 0,
      searchTotalItems: 0,
      totalPages: 0,
      currentPage: 1,
      pageSize: 10,
      contentLoading: true,
      states: [
        { name: "주문(결제완료)", value: 1 },
        { name: "검수 중", value: 2 },
        { name: "보완요청", value: 3 },
        { name: "개발진행", value: 4 },
        { name: "개발완료", value: 5 },
        { name: "운영 중", value: 6 },
        { name: "운영 종료", value: 7 },
        { name: "취소(환불)", value: 8 },
        { name: "환불", value: 9 },
        { name: "주문(결제전)", value: 10 },
      ],
      selectedState: "",
      payments: [
        { name: "카드결제", value: "card" },
        { name: "무통장입금", value: "transfer" },
      ],
      selectedPayment: "",
      searchTypeOptions: [
        { name: "회원명", value: "userName" },
        { name: "회원 이메일", value: "userEmail" },
        { name: "상품명", value: "productName" },
      ],
      search: {
        type: "",
        q: "",
        startDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        endDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        referenceFestivalStartDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        referenceFestivalEndDate: {
          value: "",
          masks: {
            input: "YYYY-MM-DD",
          },
        },
        size: {
          event: true,
          option: [
            {
              name: "10개씩",
              value: 10,
            },
            {
              name: "20개씩",
              value: 20,
            },
            {
              name: "30개씩",
              value: 30,
            },
            {
              name: "40개씩",
              value: 40,
            },
            {
              name: "50개씩",
              value: 50,
            },
          ],
          value: 10,
        },
      },
    };
  },
  created() {
    this.$Progress.start();
    this.fetch();
  },
  mounted() {
    this.$Progress.finish();
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  methods: {
    download(url, name, type, params) {
      this.downloadText = "다운로드 중";
      this.btnDisabled = true;
      params = {
        page: this.currentPage,
        size: this.pageSize,
        download: "xls",
      };
      if (this.search.q && this.search.type) {
        params[this.search.type] = this.search.q;
      }
      url = `${API_URL}${SERVICE_API_URL}/shop/order`;
      File.download(url, name, type, params, (response) => {
        console.log(response);
        // if (response.status == 200) {
        this.btnDisabled = false;
        this.downloadText = "엑셀 다운로드";
        // }
      });
    },
    onTableClick(params) {
      this.$modal.show(
        DetailOrder,
        {
          id: params.item.id,
          // getUserName: params.item.user.name,
        },
        {
          width: 978,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                this.fetch();
              }
            }
          },
        }
      );
    },
    onDeleteClick(params) {
      this.$modal.show(
        Dialog,
        {
          type: "confirm",
          title: "알림",
          content: "정말 삭제하시겠습니까?",
        },
        {
          width: 400,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params.confirm) {
              client.orderRemove(params.item.id).then(
                () => {
                  this.fetch(1);

                  this.$modal.show(
                    Dialog,
                    {
                      title: "알림",
                      content: "정상적으로 삭제되었습니다.",
                    },
                    {
                      width: 400,
                      height: "auto",
                    },
                    {
                      "before-open": () => {},
                      "before-close": () => {},
                    }
                  );
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          },
        }
      );
    },
    onEditClick(params) {
      this.$modal.show(
        RegOrder,
        {
          id: params.item.id,
          // getUserName: params.item.user.name,
        },
        {
          clickToClose: false,
          width: 672,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                this.fetch();
              }
            }
          },
        }
      );
    },
    register() {
      this.$modal.show(
        RegOrder,
        {},
        {
          clickToClose: false,
          width: 672,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": (response) => {
            if (response.params) {
              if (response.params.refresh) {
                this.fetch(1);
              }
            }
          },
        }
      );
    },
    onSearch(event) {
      event.preventDefault();
      this.fetch(1);
    },
    setDate(val) {
      let curr = new Date();
      let first = curr.getDate() - curr.getDay();
      let last = first + 6;

      let firstday = new Date(curr.setDate(first)).toUTCString();
      let lastday = new Date(curr.setDate(last)).toUTCString();

      switch (val) {
        case 0:
          (this.periodStartDt = this.$date().format("YYYY-MM-DD")),
            (this.periodEndDt = this.$date().format("YYYY-MM-DD"));
          break;
        case 1:
          (this.periodStartDt = this.$date()
            .subtract(1, "day")
            .format("YYYY-MM-DD")),
            (this.periodEndDt = this.$date()
              .subtract(1, "day")
              .format("YYYY-MM-DD"));
          break;
        case 2:
          (this.periodStartDt = this.$date(firstday).format("YYYY-MM-DD")),
            (this.periodEndDt = this.$date(lastday).format("YYYY-MM-DD"));
          break;
      }
    },
    getData() {
      let params = {};
      if (this.search.startDate.value && this.search.endDate.value) {
        params.startDate = this.$date(
          new Date(this.search.startDate.value)
        ).format("YYYY-MM-DD");

        params.endDate = this.$date(new Date(this.search.endDate.value)).format(
          "YYYY-MM-DD"
        );
      }

      if (
        this.search.referenceFestivalStartDate.value &&
        this.search.referenceFestivalEndDate.value
      ) {
        params.referenceFestivalStartDate = this.$date(
          new Date(this.search.referenceFestivalStartDate.value)
        ).format("YYYY-MM-DD");
        params.referenceFestivalEndDate = this.$date(
          new Date(this.search.referenceFestivalEndDate.value)
        ).format("YYYY-MM-DD");
      }
      params.page = this.currentPage;
      params.size = this.pageSize;
      if (this.search.type == "productName" && this.search.q) {
        params.productName = this.search.q;
      } else if (this.search.type == "userName" && this.search.q) {
        params.userName = this.search.q;
      } else if (this.search.type == "userEmail" && this.search.q) {
        params.userEmail = this.search.q;
      }
      if (this.selectedState) {
        params.status = this.selectedState;
      }
      return params;
    },

    onSortClick(value) {
      for (let i = 0; i < this.fields.length; i++) {
        this.fields[i].clickedNum = 0;
      }

      this.currentSort = value.item.key;
      if (this.currentSort.includes(".")) {
        const sortString = this.currentSort.split(".");
        this.currentSort =
          sortString[0] +
          sortString[1][0].toUpperCase() +
          sortString[1].slice(1);
      }
      this.clickedNum = this.clickedNum < 2 ? this.clickedNum + 1 : 0;
      this.fields[value.index].clickedNum = this.clickedNum;
      if (!this.contentLoading) {
        this.fetch(1);
      }
    },

    async fetch(page = 1) {
      this.currentPage = page;
      this.contentLoading = true;
      let params = this.getData();

      if (this.currentSort) {
        if (this.clickedNum == 1) {
          params.sort = `${this.currentSort}.asc`;
        } else if (this.clickedNum == 2) {
          params.sort = `${this.currentSort}.desc`;
        }
      }
      if (!this.search.type && 0 < this.search.q.length) {
        params.important = this.search.q;
        /*
        this.$modal.show(
          Dialog,
          {
            title: "알림",
            content: "검색어 항목을 선택해 주세요.",
          },
          {
            width: 400,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": () => {},
          }
        );
        return;
        */
      }
      Object.entries(this.search).forEach((row) => {
        if (row[1].event) {
          if (row[1].value.length !== 0) {
            params[row[0]] = row[1].value;
          }
        }
      });
      await client.orderFindAll(params).then(
        (response) => {
          const { data } = response;
          // console.log(data);
          data.items.forEach((item) => {
            try {
              if (
                item.reference.festivalStartDate != "0000-00-00" &&
                item.reference.festivalEndDate != "0000-00-00"
              ) {
                item.reference.festivalStartDate = `${this.$date(
                  new Date(item.reference.festivalStartDate)
                ).format("YYYY-MM-DD")} ~ ${this.$date(
                  new Date(item.reference.festivalEndDate)
                ).format("YYYY-MM-DD")}`;
              } else {
                item.reference.festivalStartDate = "기간없음";
              }
            } catch (e) {
              item.festivalStartDate = "-";
            }
            if (item.pgType == "card") {
              item.pgType = "카드결제";
            } else if (item.pgType == "transfer") {
              item.pgType = "무통장입금";
            } else {
              item.pgType = "기타";
            }
            if (item.status == 1) {
              item.status = "주문(결제완료)";
            } else if (item.status == 2) {
              item.status = "검수 중";
            } else if (item.status == 3) {
              item.status = "보완요청";
            } else if (item.status == 4) {
              item.status = "개발진행";
            } else if (item.status == 5) {
              item.status = "개발완료";
            } else if (item.status == 6) {
              item.status = "운영 중";
            } else if (item.status == 7) {
              item.status = "운영 종료";
            } else if (item.status == 8) {
              item.status = "취소(환불)";
            } else if (item.status == 9) {
              item.status = "환불";
            } else if (item.status == 10) {
              item.status = "주문(결제전)";
            }
          });

          if (
            0 < this.search.q.length ||
            (this.search.startDate.value && this.search.endDate.value) ||
            0 < this.selectedState.length
          ) {
            this.searchTotalItems = data.totalItems;
          } else if (!this.selectedState) {
            this.searchTotalItems = 0;
          }

          this.items = data.items;
          if (this.totalItems == 0) {
            this.totalItems = data.totalItems;
          }
          this.totalPages = data.totalPages;
          this.contentLoading = false;
        },
        () => {
          // console.log(message);
          this.items = [];
          // this.totalItems = 0;
          this.totalPages = 0;
          this.searchTotalItems = 0;
          this.contentLoading = false;
        }
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
